<template>
    <div class="swiper-container" ref="swiper">
        <div class="swiper-wrapper">
            <slot/>
        </div>
        <div v-show="enablePagination" class="swiper-pagination" ref="pagination"></div>
    </div>
</template>
<script>
    let num = 1;
    export default {
        name: "app-swiper",
        props: {
            enablePagination: {type: Boolean, default: false},
            pagination: {type: Object, default: () => ({})},
            autoplay: {default: true},
            loop: Boolean,
            slidePrev: {default: false},
            slideNext: {default: false}
        },
        data() {
            return {
                id: num++
            };
        },
        mounted() {
            // 创建视图
            if (!this.$children || this.$children.length <= 1) {
                return;
            }
            let opt = {
                loop: !!this.loop, //一张图片不需要循环
                autoplay: this.autoplay,
                observer: true,
                observeParents: true,
                pagination: {
                    type: "bullets",
                    clickable: true,
                    ...this.pagination,
                    el: this.$refs.pagination
                },
                slidePrev: this.slidePrev,
                slideNext: this.slideNext,
                // on:{
                //   slideChange: e=>{
                //     setTimeout(()=>this.swiper.pagination.update());
                //   }
                // }
            };
            this.swiper = new Swiper(this.$refs.swiper, opt);
        },
        destroyed() {
            this.swiper && this.swiper.slideTo(0, 0);
            this.swiper && this.swiper.destroy();
        },
        methods: {
            update() {
                // // 是否需要指示器
                this.isShow = this.$children.length > 1;
                // 销毁滚动视图
            }
        }
    };
</script>
