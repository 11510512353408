module.exports = {
    language: {
        name: 'En'
    },
    //首页
    homeTitle: {
        titleheader0: 'Conmart Expo',
        titleheader1: 'Exhibitor Services',
        titleheader2: 'Buyer Services',
        titleheader3: 'Meetings and Activities',
        listItem0: {
            duction: 'Introduction',
            activites: 'Activities',
            Pastreview: 'Scope',
            Review: 'Retrospection',
            Media: 'Media Information',
            CooperativeMedia:'CooperativeMedia'
        },
        listItem1: {
            exhibition: 'Application for Exhibition',
            Floorplan: 'Booth Map',
            DownloadCenter: 'Download Center',
            CooperativeExhibitors: 'Cooperative Exhibitors',
            Sponsor: 'Become a Sponsor',
            traffic: 'Traffic and Lodging'
        },
        listItem2: {
            RegistrationOfExhibitors: "Visit Registration",
            ScopeOfExhibition: "Exhibition Scope",
            ExhibitorsDirectory: "Exhibitor List",
            TransportationAccommodation: "Traffic and Lodging"
        },
        listItem3: {
            MainActivity: 'Activities',
            Organizingactivities: "Activity Preparations",
            ProductRecommendation: "Join for Marketing"
        }
    },
    //活动倒计时
    textHeader: {
        Exhibitors: 'Become a Spectator',
        visit: 'Free Visit',
        timeText: {
            time: '活动优惠还剩1天',
            item: '{0} days countdown to exhibition',
        }
    },
    //全新起航
    SetSail: {
        title: 'Brand New Journey of 2020 CONMART Expo',
        SailingContents: {
            Contents0: 'As “Belt and Road” and the global economic recovery jointly drive exports, the export sales of construction machinery broke out in 2019. With the significant impetus effect of the “Belt and Road” on construction machinery and the equipment demand brought by the global economic recovery, and under the drive by the multiple demands arising out of increasingly stricter national environmental protection policies and the peak period of upgrading of mechanical equipment, it is expected that the construction machinery industry would remain stable and the good development momentum in 2020.',
            Contents1: 'Under the great development trend of this industry, China (Guangzhou) International Construction Machinery and Construction Equipment Trade and Service Expo (hereinafter referred to as CONMART Expo) will be held at exhibition hall of China Import and Export Fair (exhibition hall of Canton Fair) on June 17-19, 2020 in Guangzhou.',
            Contents2: 'CONMART Expo is an international professional exhibition focusing on engineering machinery trade and service. Based in Guangzhou, it focuses on engineering machinery service and trade, post-market synergy and development, and development of the international market. It is committed to building a complete eco-friendly service system of construction equipment and engineering machinery industry.',
            Contents3: '2020 CONMART Expo will attract overseas professional buyers from 25 countries including Malaysia, India, United Arab Emirates, Turkey, Germany, South Africa, Russia, Bangladesh. The Organizing Committee is devoted to building CONMART Expo into an international, high-standard and efficient offline service exchange platform for international trade.'
        },
        textCon: {
            Exhibitors: 'Why taking part in the exhibition?',
            ExhibitionContent: 'At the 2020 CONMART Expo, hundreds of construction machinery enterprises will display their products at this stage, and the huge purchase demand of 10,000 professional buyers will be met. This is a professional stage to open the international market and go global!',
            LearnMore: 'Learn more',
            Exhibition: 'Why watching the exhibition?',
            ExhibitionContents: 'The 2020 CONMART Expo will present you with the overall development trend of the industry and numerous new technologies and products. Products from all industry chain segments will help you tap the potential market. We look forward to your participation!'
        }
    },
    //Introduction to the Exhibition
    ExhibitionIntroduction: {
        Titke: 'Introduction to the Exhibition',
        PromotionalVideo: 'Upgraded 2020 CONMART Expo',
        PropagandaContent: 'Upgraded exhibitors     Contemporary activities of all industry chain segments     insight into development opportunities in the industry and global promotion   Professional and targeted invitations to buyers'
    },
    //展会活动
    ExhibitionActivities: {
        Title: 'Exhibition Activities',
        ActivityList: {
            Title0: 'International Summit Forum',
            Title1: 'International Matchmaking of Commerce and Trade',
            Title2: 'Technical Expertise Forum',
            Title3: 'On-site Introduction and Marketing Event',
            Title4: 'Overseas Buyers Entering the Factory',
            LearnMore: 'Learn more',
            ListItem0: 'Global experts and elites in the construction machinery industry analyze and discuss the development trend of the international construction machinery market, in order to promote the development and upgrading of diversified integration of the industry.',
            ListItem1: 'The brand-new matchmaking activity for special trade events will make the matchmaking of international trade more accurate and attract outstanding elites from the international construction machinery industry to meet in Guangzhou for demand matchmaking and closer cooperation.',
            ListItem2: 'Focusing on the technology and development of China\'s construction machinery industry in the new era, the exhibition is dedicated to gathering and sharing innovative wisdom and exploring innovative exploration and growth paths under the new normal of China\'s economy.',
            ListItem3: 'It sets up a stage for enterprises to display brands and publicize products, promotes exchanges and cooperation between exhibitors and audience, adds mutual understanding through face-to-face talks, and creates an atmosphere for ideal bilateral effects.',
            ListItem4: 'Leading overseas business associations and professional buyers into enterprises for on-the-spot investigation and exchange, promoting Chinese enterprises to open the international market, helping to upgrade the business scope, and building a communication bridge between international buyers and Chinese enterprises.',
        }
    },
    //展品范围
    ScopeOfExhibits: {
        Titke: 'Scope of exhibitors',
        ExhibitsTeitle0: 'earthwork and construction machinery',
        ExhibitsTeitle1: 'construction and building materials equipment',
        ExhibitsTeitle2: 'roads and municipal equipment',
        ExhibitsTeitle3: 'concrete and sandstone equipment',
        ExhibitsTeitle4: 'spare parts and lubricants',
        ExhibitsTeitle5: 'Mining & Minerals',
        ListofExhibits0: 'According to the hot spots in the development of China\'s construction machinery industry and in combination with the development trend of the global industry, the 2020 CONMART Expo will focus on expanding exhibits in five major fields where the market demand is huge: earthwork and construction machinery, construction and building materials equipment, roads and municipal equipment, concrete and sandstone equipment, spare parts and lubricants.',
        ListofExhibits1: 'excavators, loaders, bulldozers, road rollers, cranes, high-altitude demolition machines, forklifts, pile drivers, drilling rigs, rock drills, complete sets of equipment and machinery for construction;',
        ListofExhibits2: 'tower cranes, elevators, baskets, vibrating machines, steel bars, prefabricated machines, brick making machines, mixers, aerial work platforms, metal ladders, etc.;',
        ListofExhibits3: 'graders, pavers, milling machines, road cutting machines, aerial work vehicles, small road rollers, compactors, marking machines, non-excavation drilling machines, pipe dredging machines, pipelayers, road sweepers, wreckers, etc.;',
        ListofExhibits4: 'concrete pumps, concrete mixer trucks, concrete mixing stations, mixers, concrete cutting equipment, stone crushers, sand washing machines, sand making machines, grinding machines, blasting equipment, conveying and transportation equipment, screening equipment, etc.',
        ListofExhibits5: 'engine and engine parts, chassis and structural parts, hydraulic and hydraulic element components of transmission components, pneumatic tools and components, electronic and electrical control components, work equipment and mechanism seals, drill bits and diamond products, lubricants, etc.',
        ListofExhibits6: 'Roadway excavation equipment, exploration equipment, blasting equipment, filters and accessories, flotation machines and equipment, rake mining machines, shovel loading machines, rock loading machines, geological (mine) exploration technical equipment, mine safety equipment, electric shovel, brackets, scrapers conveyor, mining vehicle, pump station, mining metallurgical equipment, reducer, air compressor, nitrogen generator, oxygen generator and other compression separation equipment, power supporting facilities, explosion-proof flame-retardant cable, etc.'
    },
    //往届回顾
    Pastreview: {
        Titke: 'Review of Previous CONMART',
        HeaderList: {
            Title0: '20,000 <p>square meters of exhibition hall</p>',
            Title1: '357',
            Title2: '25,000',
            ParticularYear0: 'Scale in 2019',
            ParticularYear1: 'Exhibitors in 2019',
            ParticularYear2: 'Audience in 2019'
        },
        listWarp: {
            warpTitle0: 'Famous enterprises showcase their products and share the platform resources',
            warpTitle1: 'Overseas buyer groups taking part in the exhibition',
            warpTitle2: 'International business negotiation – precise matchmaking',
            warpContent0: 'SANY, Zoomlion, CRRC, Haihong, Kairuitong, Saikesi Hydraulic, Lingde Breaker, Koncon, Laigong, Shunhao, Zhejiang Weilin, Suzhou Jingmi, Yantai Chengli, Shandong Ji Lu, Mingde, Qipeng, etc. take part in the exhibition.',
            warpContent1: 'Professional spectators from 14 countries and regions including Iran, Britain, Qatar, Senegal and South Africa organized groups to participate.',
            warpContent2: 'Taiwan Construction Machinery Association, Ethiopian Construction Contractors Association, Indian Industry Federation, Malaysian Builders Association, Singapore International Business Association, Cambodia International Business Association, etc.'
        }
    },
    //媒体资讯
    MediaInformation: {
        Title: 'Media Information',
        listWarp0: 'Exhibition Updates',
        listWarp1: 'Exhibitor Report',
        Report: 'seek for reports'
    },
    //申请参展
    ApplicationExhibition: {
        Title: 'Apply for joining exhibition ',
        ApplicationSubmission: 'Submit the application',
        BusinessConfirmation: 'Business confirmation',
        SuccessfulExhibition: 'Application approved',
        Company: 'Company',
        Contacts: 'Contact person',
        Mobilephone: 'Phone',
        VerificationCode: 'Verification code',
        Mailbox: 'Email',
        Submission: 'Apply for joining exhibition',
        getValidateCode: 'Get verification code',
    },
    Share: {
        title: 'Sharing with:',
        back: 'Back',
    },
    //Booth Map
    Floorplan: {
        Title: 'Booth Map'
    },
    //Cooperative Exhibitors
    CooperativeExhibitors: {
        Title: 'Cooperative Exhibitors'
    },
    //Become a Sponsor
    Sponsor: {
        Title: 'Become a Sponsor',
        AdvertisementTitle: 'Advertising position of China International Conmart Conference',
        Project: 'Project: LOGO on background plate during launch event',
        Number: 'Number: 10',
        Price: 'Price: 15,000 yuan/ads',
        Explain: 'Note: LOGO of sponsor can also be printed on the contractual tickets.',
        Enterprise: 'LOGO of sponsor can also be printed on publicity materials of forums and meetings.',
        Immediately: 'Become a sponsor immediately'
    },
    //下载中心
    DownloadCenter: {
        Title: 'Download Center',
        FileName0: '2018届国际康马展高峰论坛会会议报告',
        FileName1: '2018届国际康马展高峰论坛会会议报告',
        FileName2: '2018届国际康马展高峰论坛会会议报告',
        FileName3: '2018届国际康马展高峰论坛会会议报告',
        FileName4: '2018届国际康马展高峰论坛会会议报告',
        FileName5: '2018届国际康马展高峰论坛会会议报告',
        FileName6: '2018届国际康马展高峰论坛会会议报告',
    },
    //交通住宿
    TransportationAccommodation: {
        Title: 'Traffic and lodging',
        ConsultMap: 'Visit Map',
        Details: 'Guangzhou Pazhou International Exhibition Center',
        Address0: 'Address of exhibition hall: No. 380, Yuejiang Middle Road, Haizhu District, Guangzhou',
        Address1: 'The transportation around the exhibition hall is convenient. You can reach the Guangzhou Fair exhibition hall by subway, taxi, shuttle bus, self-driving and water bus.',
        Address2: ''
    },
    //交通住宿列表
    TransportationDetailList: {
        Left: ['Address', 'Shuttle Bus', 'Aviation', 'Taxi', 'Metro', 'Ship',],
        //穿梭巴士
        Bus: {
            Title: 'By Shuttle Bus',
            Details: 'If you need free pick up service by shuttle bus, please make advance registration and our customer service will contact you soon to arrange  your trip.'
        },
        //航空
        Air: {
            Title: 'By Aviation',
            Details: 'From Guangzhou Baiyun International Airport to Canton Fair Complex\n' +
                'Taxi\n' +
                'Guangzhou Baiyun International Airport  enjoys a great transportation network. It takes only 20 minutes to drive along the Airport Express Way from downtown to the Airport.Taxi is should be the most convenient, and yet more expensive, way to go to the airport.\n' +
                'Metro\n' +
                'To reach Area A of Canton Fair Complex, get out from Exit A of Xingang Dong Station. To reach Area B, get out from Exit A of Pazhou Station; and to Area C, Exit C of Pazhou Station.\n' +
                'Route:Jichang Nan Station ---Xinguang Dong/Pazhou Station\n' +
                'Line 3  (North extended line) Jichang Nan Station --Tiyu Xi Station \n' +
                '\n' +
                '           transfer\n' +
                'Line 3  Tiyu Xi Station ---Kecun Station\n' +
                '\n' +
                '           transfer\n' +
                'Line 8  Kecun Station--Xingang Dong Station (Area A of Canton Fair Complex)\n' +
                '                                 Pazhou Station (Area B & C of Canton Fair Complex)\n' +
                'Duration: about 43 minutes;\n' +
                'Fare: RMB 8 Yuan\n' +
                'Airport shuttle bus\n' +
                'Bus departure: about every 30 minutes. \n' +
                'Duaration:the whole journey will take about 50 minutes\n' +
                'Fare:RMB 25 Yuan.\n' +
                'Hotline: 4008-308-688 \n' +
                'Shuttle buses between hotels and the airport  \n' +
                'Panyu Hotel to Guangzhou Baiyun International Airport       \n' +
                'Welton Hotel to Guangzhou Baiyun International Airport  \n' +
                'Guangzhou Baiyun International Airport  Huashi Hotel (near Gangding, Huajing New City, South China Normal University, and Jinan University; every 30 minutes. \n' +
                'Guangzhou Baiyun International Airport  to Crowne Plaza Hotel Guangzhou City Center, via Garden Hotel; every 30 minutes. \n' +
                'Guangzhou Baiyun International Airport ←→Civil Aviation ticket office, every 10-15 minutes. \n' +
                'Guangzhou Baiyun International Airport ←→Fangcun Coach Station; every 30 minutes. \n' +
                'Guangzhou Baiyun International Airport ←→Pearl Garden Hotel in the Development District, via Grand Royal Hotel; every 45 minutes.\n' +
                'Notes: The above information is for reference only; since the operators may change their service time and content, on-site enquiries are recommended. '
        },
        //出租车
        Taxi: {
            Title: 'By Taxi',
            Details: 'As an important part of Guangzhou\'s public transportation system, taxis offer fast and convenient service.\n' +
                '\n' +
                'Flag-down fare: 10 Yuan for 2.5 Km; the distance exceeding 2.5 km will be charged at 2.6 Yuan per km. \n' +
                'On-call Taxi service: 96900\n' +
                '\n' +
                'The boarding time of Exhibition Road (A, B Hall Middle Road near the Exhibition Road)\n' +
                '\n' +
                'Canton Fair Complex pickup zone\n' +
                '\n' +
                'Taxis are not allowed to pick up passengers in any other regions except \n' +
                'Area A & Area B:Complex Mid. Road \n' +
                'Area C:at the east side of Complex AreaArea C.'
        },
        //地铁
        Metro: {
            Title: 'By Metro',
            Details: 'Route: Jichang Nan Station --- Xinguang Dong/Pazhou Station\n' +
                'Line 3 (North extended line) Jichang Nan Station --Tiyu Xi Station  \n' +
                '                           transfer\n' +
                'Line 3   Tiyu Xi Station ---Kecun Station \n' +
                '                           transfer\n' +
                'Line 8   Kecun Station--Xingang Dong Station (Area A of Canton Fair Complex)\n' +
                '                                  Pazhou Station (Area B & Area C of Canton Fair Complex)\n' +
                'Duration: about 43 minutes; Fare: 8 Yuan\n' +
                'Railway Stations to Canton Fair Complex'
        },
        //轮船
        Hume: {
            Title: 'By Ship',
            Details: '<a href="http://www.cksp.com.hk/eng/skypier/online_purchase/main.html" target="_blank">CHU KONG</a> ' +
                '<a href="https://www.cksp.com.hk/en" target="_blank">PASSENGER TRANSPORTS</a>\n' +
                '\n' +
                '250 sailings everyday connecting 15 ports in Macau, Canton and HK downtown and airport'
        }
    },

    //底部
    Footer: {
        Guidance: 'Guiding Unit',
        GuidanceContent0: 'Chinese Mechanical Engineering Society',
        GuidanceContent1: 'Sponsors',
        GuidanceContent2: 'Overseas Investment Union of The Investment Association of China',
        GuidanceContent3: 'Guangzhou Construction Machinery Association',
        GuidanceContent4: 'CMIA工矿机械国际贸易联盟',
        GuidanceContent5: 'Organizers',
        GuidanceContent6: 'Shenzhen HUUKEE International Exhibition and Media Co., Ltd.',
        Overseas: 'Domestic and overseas media',
        OverseasContent0: '第一工程机械网',
        OverseasContent1: '工程机械之家',
        OverseasContent2: '《建设机械技术与管理》杂志',
        OverseasContent3: '《工程机械》杂志社',
        OverseasContent4: '《筑路机械与施工机械化》杂志社',
        OverseasContent5: '工程机械网',
        OverseasContent6: '工程机械信息网',
        OverseasContent7: '慧聪网',
        OverseasContent8: '胡子里',
        OverseasContent9: '瀛沣工程机械网',
        Contact: 'Contact us',
        Account: 'Wechat Official<br/>Account of Conmart-Expo',
        FooterButtom: "All rights reserved by Shenzhen HUUKEE International Exhibition and Media Co., Ltd. ICP filing No.: ",
        FooterButtom2: "粤ICP备16129498号",
        // FooterButtom2: "Yue ICP B. No. 16129498-2",

        unitList:[
            '慧聪工程机械网',
            '路面机械网',
            '第一工程机械网',
            '工程机械在线',
            '砼商网',
            '铁甲网',
            '挖掘机网',
            '环球破碎网',
            '瀛丰工程机械网',
            '工程机械观察',
            '工程机械品牌网',
            '工程机械周刊',
            '机电之家网',
            '中华轴承网',
            '液压网',
            '矿业报网网',
        ]
    },
    //Visit registration
    Newedition: {
        Title: 'Visit registration',
        Technological: {
            Item0: 'Submit application',
            Item1: 'Customer service',
            Item2: 'Visit as scheduled',
        },
        Contacts: 'Contact person',
        Mobilephone: 'Cellphone',
        VerificationCode: 'Verification code',
        mailbox: 'E-mail',
        Apply: "Apply for application for free",
        getValidateCode: 'Get verification code'

    },
    //展品范围
    ScopeExhibits: {
        Title: 'Scope of exhibitors',
        Introduces: 'According to the hot spots in the development of China\'s construction machinery industry and in combination with the development trend of the global industry, the 2020 CONMART Expo will focus on expanding exhibits in five major fields where the market demand is huge: earthwork and construction machinery, construction and building materials equipment, roads and municipal equipment, concrete and sandstone equipment, spare parts and lubricants.',
        Equipment: {
            EquipmentType0: 'earthwork and construction machinery',
            EquipmentType1: 'construction and building materials equipment',
            EquipmentType2: 'roads and municipal equipment',
            EquipmentType3: 'concrete and sandstone equipment',
            EquipmentType4: 'spare parts and lubricants',
            EquipmentType5: 'Mining & Minerals',
            EquipmentContent0: 'excavators, loaders, bulldozers, road rollers, cranes, high-altitude demolition machines, forklifts, pile drivers, drilling rigs, rock drills, complete sets of equipment and machinery for construction;',
            EquipmentContent1: 'tower cranes, elevators, baskets, vibrating machines, steel bars, prefabricated machines, brick making machines, mixers, aerial work platforms, metal ladders, etc.;',
            EquipmentContent2: 'graders, pavers, milling machines, road cutting machines, aerial work vehicles, small road rollers, compactors, marking machines, non-excavation drilling machines, pipe dredging machines, pipelayers, road sweepers, wreckers, etc.;',
            EquipmentContent3: 'concrete pumps, concrete mixer trucks, concrete mixing stations, mixers, concrete cutting equipment, stone crushers, sand washing machines, sand making machines, grinding machines, blasting equipment, conveying and transportation equipment, screening equipment, etc.',
            EquipmentContent4: 'engine and engine parts, chassis and structural parts, hydraulic and hydraulic element components of transmission components, pneumatic tools and components, electronic and electrical control components, work equipment and mechanism seals, drill bits and diamond products, lubricants, etc.',
            EquipmentContent5: 'Roadway excavation equipment, exploration equipment, blasting equipment, filters and accessories, flotation machines and equipment, rake mining machines, shovel loading machines, rock loading machines, geological (mine) exploration technical equipment, mine safety equipment, electric shovel, brackets, scrapers conveyor, mining vehicle, pump station, mining metallurgical equipment, reducer, air compressor, nitrogen generator, oxygen generator and other compression separation equipment, power supporting facilities, explosion-proof flame-retardant cable, etc.'
        }
    },
    //Exhibitor List{
    ExhibitorsDirectorys: {
        Title: 'Exhibitor List'
    },
    //Activities
    Activity: {
        Title: 'Activities',
        Highlight:'Highlight',
        Time:'Time',
        Address:'Address'
    },
    SponsorShipText: {
        title: 'List of Items under Sponsorship',
        titleList: ['Sponsors of hand bags of forum/dinner party ',
            'Sponsors of invitations to forum/dinner party',
            'Sponsor of notebooks',],
        price: 'Price',
        number: 'Number',
        wan: '{0}0,000 yuan',
        two: '25,000yuan',
        exclusive: 'Exclusive',
        only2: 'Only 2',
        note: 'Note',
        remarks: [
            'Logo printed on information pack',
            'Logo printed on invitations',
            'Logo printed on all notebooks of the meetings',
        ],
        yourContactInfo: '',
        submit: 'Submit'
    },
    //Activity Preparations
    OrganizingActivities: {
        Input: {
            title: 'Basic information',
            simpleDescription: 'Simple description',
        },

        applyRecommend: 'Application for marketing',
        Title: 'Activity Preparations',
        Technological0: 'Submit application',
        Technological1: 'Business communication',
        Technological2: 'Send confirmation letter via e-mail and short message',
        Preparefordeparture: {
            listItemn0: {
                Text: {
                    title: 'China Overseas Project Construction Summit Forum',
                    text: 'Venue: International Business Activity Zone'
                },
                Theme: {
                    theme: 'Subject：',
                    content: 'Global construction machinery leaders jointly discuss and analyze the "the belt and road initiative" policy, seize new opportunities for development, expand channels, strengthen projects and build platforms with an open concept to speed up the pace of opening to the outside world.',
                    time: 'Time: June 17, 2020, 14:00-15:30'
                }
            },
            listItemn1: {
                Text: {
                    title: '2020 CTO Summit on Construction Machinery Hydraulic',
                    text: 'Venue: authoritative forum area'
                },
                Theme: {
                    theme: 'Subject：',
                    content: 'The aim is to further promote scientific and technological innovation in China\'s construction machinery industry and help enterprises to realize upgrading strategies by relying on technological innovation and management innovation.',
                    time: 'Time: June 18, 2020, 10:00-11:30'
                }
            },
            listItemn2: {
                Text: {
                    title: 'Promotional Meeting of Famous Enterprises',
                    text: 'Venue: Brand Promotion Area'
                },
                Theme: {
                    theme: 'Subject：',
                    content: 'Win the public praise of customers and promote the sales of products, show and enhance brand awareness and reputation.',
                    time: 'Time: June 18, 2020, 10:00-11:30'
                }
            }
        },
        Apply: 'Application for Organizing Activities',
        ApplicationContent: 'Focus on Regional "Scientific Innovation" to Help Industry "Integration" and Promote Yangtze River Delta Region'
    },
    //Product Marketing
    ProductRecommendation: {
        Title: "Product Marketing",
        RecommendWarp: {
            boxWarp0: {
                title: 'Investment attracting and marketing conference of Wapeibao project (Guangzhou Station)',
                company: 'Company: Hangzhou Wapeibao Information Technology Co., Ltd.',
                time: 'Time: August 1, 2019, 09:30-11:30',
                place: 'Place：Blue Activity Zone of Exhibition Hall 7.1'
            },
            boxWarp1: {
                title: 'Guangzhou Yutuo new products launch event and signing ceremony',
                company: 'Company: Guangzhou Yutuo Test and Control Technology Co., Ltd.',
                time: 'Time: August 1, 2019, 11:00-12:00',
                place: 'Place: Red Activity Zone of Exhibition Hall 6.1'
            },
            boxWarp2: {
                title: 'Daekko new hydraulic hammer launch event',
                company: 'Company: Zhejiang Lingde Breaker Co., Ltd.',
                time: 'Time: August 1, 2019, 15:30-16:30',
                place: 'Place: Golden Activity Zone of Exhibition Hall 7.1'
            }
        }
    },
    news: {
        details: {
            title: 'Detailed Information',
        }
    },
    confer: {
        learnMore: 'Learn More',
    },
    'Validate': {
        'CompanyNotNull': 'Company cannot be empty.',
    }
};
