import Audience from '../../publicApi/exhibition'
export default {
    namespaced: true,

    state: () => ({
        company: '',
        Contacts: '',
        Mobilephone: '',
        VerificationCode: '',
        mailbox: ''

    }),
    actions: {


    },
    mutations: {

    }
}