<template>
    <div class="container">
        <router-view></router-view>
    </div>
</template>
<script>
    export default {}
</script>

<style lang="scss">
    html {
        font-size: 10vw;
    }

    html, body {
        width: 100%;
    }

    .container {
        font-size: 14px;
    }

    .center {
        text-align: center;
    }

    .swiper-pagination-bullet {
        width: 42px;
        height: 4px;
        display: inline-block;
        background: #CCCCCC;
        border-radius: 0;
    }

    .none {
        display: none;
    }

    .checkbox-none {
        position: absolute;
        z-index: -1;
        width: 1px;
        height: 1px;
        visibility: hidden;
        opacity: 0;
        top: 0;
        left: 0;
    }

    .menu-button {

    }

</style>

<style lang="scss">
    html {

        // 公共样式
        .Submission,
        .VerificationCode .btn,
        .report,
        .Applications .box-ssing .Apply,
        .listWarp .list_itemn .activity_theme,
        .promotion,
        .Sponsor .Sponsor_main .Sponsorshipcontent .right .Advertisement .main_right .immediately {
            transition: .2s linear;
            border-radius: 2px;
            user-select: none;

            &:hover {
                background: darken(#FAD214, 8%);
            }
        }
    }

    input, select, button {
        -webkit-appearance: none;

    }
</style>