import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

export function createRouter() {

    const router = new VueRouter({
        mode: 'history',
        routes: [{
            path: '/:lang(zh|en)',
            component: () =>
                import ('@/views/main.vue'),
            children: [{
                name: 'Home', //首页/
                path: 'Home',
                component: () =>
                    import ('@/views/home/Home'),
            },
                {
                    name: 'Summit', //往届回顾
                    path: 'Summit',
                    component: () =>
                        import ('@/views/Summit/Summit'),
                }, {
                    name: 'buyerservice', //买家服务
                    path: 'buyerservice',

                    component: () =>
                        import ('@/views/buyerservice/Buyerservice')
                },
                {
                    name: 'conferenceactivities', //会议活动
                    path: 'conferenceactivities',

                    component: () =>
                        import ('@/views/conferenceactivities/Conferenceactivities')
                },
                {
                    name: 'exhibitorsService', //展商服务
                    path: 'exhibitorsService',
                    component: () =>
                        import ('@/views/exhibitorsService/Exhibitorsservice')
                },
                {
                    name: 'detailss', //详情页
                    path: 'news/:id',
                    redirect: 'article/:id/news',
                    props: true,
                    // component: () =>
                    //     import ('@/views/detailss/Details')
                }, {
                    name: 'detailssType', //详情页
                    path: 'article/:id/:type',
                    props: true,
                    component: () =>
                        import ('@/views/detailss/Details')
                },
                {
                    name: 'information', //咨询
                    path: 'information',

                    component: () =>
                        import ('@/views/information/Information')
                },
                {
                    name: 'SignupImmediately', //立即报名
                    path: 'SignupImmediately',

                    component: () =>
                        import ('@/views/SignupImmediately/SignupImmediately')
                },
                {
                    name: 'pastreview', //往届回顾
                    path: 'pastreview',

                    component: () =>
                        import ('@/views/pastreview/Pastreview')
                },
                {
                    name: 'Sponsorship', //赞助列表
                    path: 'Sponsorship',

                    component: () =>
                        import ('@/views/Sponsorship/Sponsorship')
                },
                {
                    name: 'Organizingactivities', //筹办活动
                    path: 'Organizingactivities',

                    component: () =>
                        import ('@/views/Organizingactivities/index')
                },
                {
                    name: 'Applicationforpromotion', //申请推介
                    path: 'Applicationforpromotion',

                    component: () =>
                        import ('@/views/Applicationforpromotion/Applicationforpromotion')
                },
                {
                    name: 'Activitydetails', //活动详情页
                    path: 'ActivityDetails/:id',
                    component: () =>
                        import ('@/views/ActivityDetails/Activitydetails')
                },
                {
                    name: 'SeekingReport', //寻求报道
                    path: 'SeekingReport',
                    component: () =>
                        import ('@/views/SeekingReport/SeekingReport')
                },

            ]
        },
            {
                path: '/',
                redirect: '/zh/home'
            },
            // {
            //     path: '/404',
            //     component: () =>
            //         import ('../components/NotFind/notfind')
            // },
            {
                path: '**',
                redirect: '/'
            }
        ],
        scrollBehavior(to, from, savedPosition) {
            return {x: 0, y: 0}
        }
    })

    // router.beforeEach((to, from, next) => {
    //     // 判断将要去的路由是否需要登录状态
    //     if (to.meta.needLogin) {
    //       // 登录状态的校验
    //       if (store.state.user.userInfo) {
    //         // 放行
    //         next()
    //       } else {
    //         // 不存在，去登录
    //         next({
    //           path: '/login',
    //           query: {
    //             redirect: to.fullPath
    //           }
    //         })
    //       }
    //     } else {
    //       next()
    //     }
    //   })
    return router
}