<template>
    <div class="swiper-slide">
        <slot/>
    </div>
</template>
<script>
export default {
    name:'app-swiper-item',
}
</script>

<style lang="less" scoped>

</style>
