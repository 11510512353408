module.exports = {
    language: {
        name: '中文'
    },
    //首页
    homeTitle: {
        titleheader0: '康马展',
        titleheader1: '展商服务',
        titleheader2: '买家服务',
        titleheader3: '会议 · 活动',
        listItem0: {
            duction: '展会介绍',
            activites: '展会活动',
            Pastreview: '展会范围',
            Review: '往届回顾',
            Media: '媒体资讯',
            CooperativeMedia:'合作媒体'
        },
        listItem1: {
            exhibition: '申请参展',
            Floorplan: '展位图',
            DownloadCenter: '下载中心',
            CooperativeExhibitors: '合作展商',
            Sponsor: '我要赞助',
            traffic: '交通住宿'
        },
        listItem2: {
            RegistrationOfExhibitors: "参观登记",
            ScopeOfExhibition: "参展范围",
            ExhibitorsDirectory: "展商名录",
            TransportationAccommodation: "交通住宿"
        },
        listItem3: {
            MainActivity: '活动',
            Organizingactivities: "筹办活动",
            ProductRecommendation: "我要推介"
        }
    },
    //活动倒计时
    textHeader: {
        Exhibitors: '我要参展',
        visit: '免费参观',
        timeText: {
            time: '活动优惠还剩1天',
            item: '距离会展开幕还有{0}天',
        }
    },
    //全新起航
    SetSail: {
        title: '2020康马展 全新起航',
        SailingContents: {
            Contents0: '“一带一路”与全球经济复苏共驱出口，2019年工程机械出口销量爆发。凭借“一带一路”对工程机械显著的带动效应与全球经济复苏带来的设备需求，在国家环保政策趋严和机械设备更新换代高峰期等多重需求驱动下，预计2020年工程机械行业有望保持又稳又好发展态势，令人期待。',
            Contents1: '在此行业大发展趋势下，广州国际工程机械•建筑设备贸易及服务博览会 (以下简称康马展)，将于2020年6月17-19日在广州•进出口商品交易会展馆（广交会展馆）举行。',
            Contents2: '康马展是专注于工程机械贸易与服务的国际性专业展会，立足广州，聚焦工程机械服务与贸易、后市场协同融合、发展国际市场三大重点，致力于打造建筑设备、工程机械行业完整生态服务系统。',
            Contents3: '届时，2020康马展将吸引来自马来西亚、印度、阿联酋、土耳其、德国、南非、俄罗斯、孟加拉等25个国家的海外专业买家组团观展。组委会致力于将康马展打造为集国际化、高规格、高效对接为一体的国际贸易线下服务交流平台！'
        },
        textCon: {
            Exhibitors: '为什么要参展',
            ExhibitionContent: '2020康马展，数百家工程机械企业同台竞技，万人专业买家海量采购需求释放，这是开拓国际市场、走向海外的专业性舞台!',
            LearnMore: '了解更多',
            Exhibition: '为什么要观展',
            ExhibitionContents: '2020康马展，将为您呈现行业整体发展趋势及众多新技术、新产品，全产业链细分领域产品助您挖掘潜在市场，期待您的参与！'
        }
    },
    //展会介绍
    ExhibitionIntroduction: {
        Titke: '展会介绍',
        PromotionalVideo: '2020康马展全新升级',
        PropagandaContent: '展品升级 全产业链细分领域同期活动 洞悉行业发展契机全球推广 专业精准买家邀约'
    },
    //展会活动
    ExhibitionActivities: {
        Title: '展会活动',
        ActivityList: {
            Title0: '国际高峰论坛',
            Title1: '国际商贸对接',
            Title2: '专业技术论坛',
            Title3: '现场推介会',
            Title4: '海外买家走进工厂',
            LearnMore: '了解更多',
            ListItem0: '汇聚全球工程机械专家与精英，对国际工程机械市场的发展趋势进行分析与讨论，助推产业多元融合发展与升级。',
            ListItem1: '全新的商贸专场对接活动，让国际商贸对接更为精准，吸引优秀的国际工程机械行业精英聚首广州，进行需求对接，让相互合作更为紧密。',
            ListItem2: '聚焦新时代下的工程机械行业技术与发展，致力于汇聚和分享创新智慧，探寻在经济新常态下的创新探索与成长路径。',
            ListItem3: '为企业搭建展示品牌、宣传产品的舞台，促进展商与观众的交流与合作，通过面对面洽谈的方式增进双方彼此的了解，营造气氛促成理想的双边效果。',
            ListItem4: '带领海外商协会及专业买家走进企业进行实地考察与交流，推动企业开拓国际市场，助力业务范围升级，也为国际买家与企业搭建沟通桥梁。',
        }
    },
    //展品范围
    ScopeOfExhibits: {
        Titke: '展品范围',
        ExhibitsTeitle0: '土方及施工机械',
        ExhibitsTeitle1: '建筑及建材设备',
        ExhibitsTeitle2: '道路及市政设备',
        ExhibitsTeitle3: '混凝土及砂石设备',
        ExhibitsTeitle4: '零部件及润滑油',
        ExhibitsTeitle5: '矿山机械及设备',
        ListofExhibits0: '根据工程机械行业发展热点并结合全球行业发展趋势，2020康马展将着重拓展市场需求火热的五大领域的展品展示：土方及施工机械、建筑及建材设备、道路及市政设备、混凝土及砂石设备、零部件及润滑油。',
        ListofExhibits1: '挖掘机、装载机、推土机、压路机、起重机、高空拆除机、叉车、桩机、钻机、凿岩机、施工用成套设备与机械等；',
        ListofExhibits2: '塔吊机、吊篮、振动机、钢筋机、预制机械、制砖机、搅拌机、高空作业平台、升降机、金属梯等；',
        ListofExhibits3: '平地机、摊铺机、铣刨机、道路切割机、高空作业车、小型压路机、夯实机、划线机、非开挖钻机、管道疏通机械、铺管机、道路清扫车、清障车等；',
        ListofExhibits4: '混凝土泵、混凝土搅拌车、混凝土搅拌站、搅拌机、混凝土切割设备、石料破碎机、洗沙机、制砂机等；',
        ListofExhibits5: '发动机及发动机零件、底盘及结构件、传动部件液压与液力元件、气动工具及元、电子电器控制元件、工作装置及机构密封件、钻具钻头及金刚石制品、润滑油等；',
        ListofExhibits6: '巷道掘进设备、勘探设备、爆破设备、滤器及附件、浮选机及设备、扒矿机、铲装机、装岩机、地质（矿山）勘探技术装备、矿山安全设备、电铲、支架、刮板输送机、采矿汽车、泵站、矿山冶金设备、减速机、空压机、制氮机、制氧机等压缩分离设备、电力配套设施、防爆阻燃电缆电线等。'
    },
    //往届回顾
    Pastreview: {
        Titke: '往届回顾',
        HeaderList: {
            Title0: '展会面积20000平米',
            Title1: '357家',
            Title2: '25000名',
            ParticularYear0: '2019年规模',
            ParticularYear1: '2019年展商',
            ParticularYear2: '2019年观众'
        },
        listWarp: {
            warpTitle0: '知名企业同台竞技 共享平台资源',
            warpTitle1: '海内外专业观众云集 国际买家团 组团参展',
            warpTitle2: '国际商贸洽谈——精准对接',
            warpContent0: '三一、中联、北京中车、海宏、凯锐通、赛克思液压、岭德重工、孔山重工、山东莱工、顺昊、浙江伟林、苏川精密、烟台诚立、山东吉鲁、铭德、广州起鹏等工程机械企业均携旗下明星产品亮相此次康马展。',
            warpContent1: '来自伊朗、英国、卡塔尔、塞内加尔、南非等14个国家和地区的专业观众组团参加',
            warpContent2: '台湾建设机械协会、埃塞尔比亚建筑承包商协会、印度工业联合会、马来西亚建筑商协会、新加坡、柬埔寨等国际商协会'
        }
    },
    //媒体资讯
    MediaInformation: {
        Title: '媒体资讯',
        listWarp0: '展会动态',
        listWarp1: '展商报道',
        Report: '我要寻求报道'
    },
    //申请参展
    ApplicationExhibition: {
        Title: '申请参展',
        ApplicationSubmission: '提交申请',
        BusinessConfirmation: '商务确认',
        SuccessfulExhibition: '成功参展',
        Company: '公司',
        Contacts: '联系人',
        Mobilephone: '手机',
        VerificationCode: '验证码',
        Mailbox: '邮箱',
        Submission: '申请参展',
        getValidateCode: '获取验证码',
    },
    Share: {
        title: '分享至：',
        back: '返回',
    },
    //展位图
    Floorplan: {
        Title: '展位图'
    },
    //合作展商
    CooperativeExhibitors: {
        Title: '合作展商'
    },
    //我要赞助
    Sponsor: {
        Title: '我要赞助',
        AdvertisementTitle: '国际康马会议广告位',
        Project: '项目：发布会背景板LOGO',
        Number: '数量：限10个',
        Price: '价格：15,000元/个',
        Explain: '说明：赞助企业LOGO可在相关协议门票上一并体现。',
        Enterprise: '赞助企业LOGO可在论坛会议宣传上一并体现。',
        Immediately: '立即赞助'
    },
    //下载中心
    DownloadCenter: {
        Title: '下载中心',
        FileName0: '2018届国际康马展高峰论坛会会议报告',
        FileName1: '2018届国际康马展高峰论坛会会议报告',
        FileName2: '2018届国际康马展高峰论坛会会议报告',
        FileName3: '2018届国际康马展高峰论坛会会议报告',
        FileName4: '2018届国际康马展高峰论坛会会议报告',
        FileName5: '2018届国际康马展高峰论坛会会议报告',
        FileName6: '2018届国际康马展高峰论坛会会议报告',
    },
    //交通住宿
    TransportationAccommodation: {
        Title: '交通住宿',
        ConsultMap: '查看地图',
        Details: '广州琶洲国际会展中心',
        Address0: '展馆地址：广州海珠区阅江中路380号',
        Address1: '展馆周边交通便利，您可通过地铁、出租车、穿梭巴士、自驾车、水上巴士等方式方便快捷的到达广交会展馆。',
        Address2: '通过预登记系统留下您的联系方式，我们的客服中心会尽快与您联系，为您提供香港。广州、上海机场的接机服务。'
    },
    //交通住宿列表
    TransportationDetailList: {
        Left: ['地址', '穿梭巴士', '航空', '出租车', '地铁', '轮船',],
        //穿梭巴士
        Bus: {
            Title: '穿梭巴士',
            Details: '通过预登记系统留下您的联系方式，我们的客服中心会尽快与您联系，为您提供香港、广州、上海机场的接机服务。 '
        },
        //航空
        Air: {
            Title: '航空',
            Details: '广州白云机场地处白云区人和镇和花都区新华街道、花山镇、花东镇交界处，距展馆距离约为45公里。每天有数以千计的航班往返世界各地。广州白云国际机场的交通十分便利，穿梭巴士往返市区各地，地铁、高速公路直达展馆。\n' +
                '\n' +
                '机场咨询电话：008620-86122933 \n' +
                '\n' +
                '\n' +
                ' \n' +
                '\n' +
                '从机场到广交会展馆 \n' +
                '\n' +
                '你可以从广州白云机场直接乘坐出租车或地铁前往展馆。 \n' +
                '\n' +
                '（一）出租车\n' +
                '你可以在到达大厅门口的的士站乘坐出租车直接前往广展馆。广州市政府规定：出租车单价2.6元/公里、起表价10元（含2.5公里租金），除按表支付租金外，乘客还需支付乘坐出租车期间所产生的高速公路通行费和过桥费。 \n' +
                '\n' +
                '（二）地铁\n' +
                '你也可以从达到大厅到机场南地铁站乘坐地铁前往展馆。乘坐地铁的换乘路线和下车站点如下：\n' +
                '展馆A区可在新港东站A出口下车。广交会展馆B区可在琶洲站A出口下车，C区可在琶洲站C出口下车\n' +
                '机场南（三号线，体育西路方向）——体育西路\n' +
                '           换乘三号线（番禺广场方向）\n' +
                '体育西路——客村\n' +
                '           换乘八号线（万胜围方向）\n' +
                '客村——新港东（展馆A区，由A出口出站）\n' +
                '           或者琶洲站（由A出口或B出口出站到达展馆B区，由C出口出站向西行约300米到达展馆C区）\n'
        },
        //出租车
        Taxi: {
            Title: '出租',
            Details: '出租车是广州公共交通的重要组成部分，方便快捷，扬手即停，按表收费。\n' +
                '广州市政府规定：出租车单价2.6元/公里、起表价10元（含2.5公里租金）;除按表支付租金外，若行程需要经行额外收费路段，乘客还需额外支付期间所产生的高速公路通行费和过桥费等相关费用。\n' +
                '电召出租车电话：96900\n' +
                '温馨提示：出租车只能在展馆A区的展场中路出租车上客通道和展馆C区展馆东侧上客点上落客，其他路段不允许上落客。 '
        },
        //地铁
        Metro: {
            Title: '地铁',
            Details: '地铁8号线经过琶洲国际会展中心展馆。乘坐地铁，地铁新港东站A出入口可到展馆A区，或者从地铁琶洲站的A、B出入口可到展馆B区，也可以在地铁琶洲站C出入口向西步行300米到展馆C区。您可以就近乘坐地铁到琶洲展馆。\n' +
                '路线：机场南站---新港东/琶洲站\n' +
                '3号线（北延长线）机场南站 - 体育西路站\n' +
                '          转乘\n' +
                '3号线  体育西路站 - 客村站\n' +
                '          可转乘\n' +
                '8号线客村站 - 新港东站（广交会琶洲展馆A区）\n' +
                '琶洲站（广交会展馆B区和C区）\n' +
                '时间：约43分钟，票价：8元 '
        },
        //轮船（这是日语）
        Hume: {
            Title: '轮船',
            Details: '请前往<a href="http://www.cksp.com.hk/zh-HK" target="_blank">珠江客运有限公司官网</a>预订船票 '
        }
    },

    //底部
    Footer: {
        Guidance: '指导单位',
        GuidanceContent0: '工程机械学会',
        GuidanceContent1: '主办单位',
        GuidanceContent2: '中国投资协会海外投资联合会',
        GuidanceContent3: '广州工程机械行业协会',
        GuidanceContent4: 'CMIA工矿机械国际贸易联盟',
        GuidanceContent5: '承办单位',
        GuidanceContent6: '深圳汇客国际会展传媒有限公司',
        Overseas: '海内外媒体',
        OverseasContent0: '第一工程机械网',
        OverseasContent1: '工程机械之家',
        OverseasContent2: '《建设机械技术与管理》杂志',
        OverseasContent3: '《工程机械》杂志社',
        OverseasContent4: '《筑路机械与施工机械化》杂志社',
        OverseasContent5: '工程机械网',
        OverseasContent6: '工程机械信息网',
        OverseasContent7: '慧聪网',
        OverseasContent8: '胡子里',
        OverseasContent9: '瀛沣工程机械网',
        Contact: '联系我们',
        Account: '康马展微信公众号',
        FooterButtom: " ©2018-2019 ConmartExpo.com 深圳市汇客国际会展传媒有限公司 版权所有 ICP备案号：",
        FooterButtom2: "粤ICP备16129498号",

        unitList: [
            '慧聪工程机械网',
            '路面机械网',
            '第一工程机械网',
            '工程机械在线',
            '砼商网',
            '铁甲网',
            '挖掘机网',
            '环球破碎网',
            '瀛丰工程机械网',
            '工程机械观察',
            '工程机械品牌网',
            '工程机械周刊',
            '机电之家网',
            '中华轴承网',
            '液压网',
            '矿业报网网',
        ],
    },
    //参展登记
    Newedition: {
        Title: '参观登记',
        Technological: {
            Item0: '提交申请',
            Item1: '客服服务',
            Item2: '如期参观',
        },
        Contacts: '联系人',
        Mobilephone: '手机',
        VerificationCode: '验证码',
        mailbox: '邮箱',
        Apply: "免费申请参观",
        getValidateCode: '获取验证码'

    },
    //展品范围
    ScopeExhibits: {
        Title: '展品范围',
        Introduces: '根据工程机械行业发展热点并结合全球行业发展趋势，2020康马展将着重拓展市场需求火热的五大领域的展品展示：土方及施工机械、建筑及建材设备、道路及市政设备、混凝土及砂石设备、零部件及润滑油。',
        Equipment: {
            EquipmentType0: '土方及施工机械',
            EquipmentType1: '建筑及建材设备',
            EquipmentType2: '道路及市政设备',
            EquipmentType3: '混凝土及砂石设备',
            EquipmentType4: '零部件及润滑油',
            EquipmentType5: '矿山机械及设备',
            EquipmentContent0: '挖掘机、装载机、推土机、压路机、起重机、高空拆除机、叉车、桩机、钻机、凿岩机、施工用成套设备与机械等；',
            EquipmentContent1: '塔吊机、吊篮、振动机、钢筋机、预制机械、制砖机、搅拌机、高空作业平台、升降机、金属梯等；',
            EquipmentContent2: '平地机、摊铺机、铣刨机、道路切割机、高空作业车、小型压路机、夯实机、划线机、非开挖钻机、管道疏通机械、铺管机、道路清扫车、清障车等；',
            EquipmentContent3: '混凝土泵、混凝土搅拌车、混凝土搅拌站、搅拌机、混凝土切割设备、石料破碎机、洗沙机、制砂机等；',
            EquipmentContent4: '发动机及发动机零件、底盘及结构件、传动部件液压与液力元件、气动工具及元、电子电器控制元件、工作装置及机构密封件、钻具钻头及金刚石制品、润滑油等；',
            EquipmentContent5: '巷道掘进设备、勘探设备、爆破设备、滤器及附件、浮选机及设备、扒矿机、铲装机、装岩机、地质（矿山）勘探技术装备、矿山安全设备、电铲、支架、刮板输送机、采矿汽车、泵站、矿山冶金设备、减速机、空压机、制氮机、制氧机等压缩分离设备、电力配套设施、防爆阻燃电缆电线等。'
        }
    },
    //展商名录{
    ExhibitorsDirectorys: {
        Title: '展商名录'
    },
    //活动
    Activity: {
        Title: '活动',
        Highlight:'亮点',
        Time:'时间',
        Address:'地址'
    },
    SponsorShipText: {
        title: '赞助项目列表',
        titleList: [
            '论坛/晚宴手提袋赞助商',
            '论坛/晚宴邀请函赞助商',
            '记事本赞助商',
        ],
        price: 'Price',
        number: '配套',
        wan: '{0}万元',
        two: '2.5万元',
        exclusive: '独家',
        only2: 'Only 2',
        note: '备注',
        remarks: [
            '资料袋上印logo',
            '邀请函上印公司logo',
            '会议期间所用记事本有贵公司logo展示',
        ],
        yourContactInfo: '',
        submit: '提交'
    },
    //筹办活动
    OrganizingActivities: {
        Input: {
            title: '基本信息',
            simpleDescription: '简单描述',
        },
        applyRecommend: '申请推介',
        Title: '筹办活动',
        Technological0: '提交申请',
        Technological1: '商务沟通',
        Technological2: '邮件短信通知确认函',
        Preparefordeparture: {
            listItemn0: {
                Text: {
                    title: '海外工程项目建设高峰论坛',
                    text: '场地：国际商贸活动区'
                },
                Theme: {
                    theme: '主题：',
                    content: '全球工程机械领袖共同探讨分析“一带一路”政策，抢抓发展新机遇，以开放的理念拓通道、强项目、搭平台，加快对外开放步伐。',
                    time: '时间：2020年6月17日 14:00-15:30'
                }
            },
            listItemn1: {
                Text: {
                    title: '2020工程机械液压CTO峰会',
                    text: '场地：权威论坛区'
                },
                Theme: {
                    theme: '主题：',
                    content: '旨在进一步推动工程机械行业科技创新，助力企业依靠技术创新和管理创新实现升级战略。',
                    time: '时间：2020年6月18日 10:00-11:30'
                }
            },
            listItemn2: {
                Text: {
                    title: '名企推介会',
                    text: '场地：品牌推介区'
                },
                Theme: {
                    theme: '主题：',
                    content: '赢得客户的口碑，促进产品的销售；展示和提升品牌知名度和美誉度。',
                    time: '时间：2020年6月18日 10:00-11:30'
                }
            }
        },
        Apply: '申请筹办活动',
        ApplicationContent: '聚焦区域“科创”，助力产业“整合”， 推动长三角地区'
    },
    //产品推介
    ProductRecommendation: {
        Title: "产品推介",
        RecommendWarp: {
            boxWarp0: {
                title: '挖配宝项目招商推介会（广州站）',
                company: '公司：杭州挖配宝信息技术有限公司',
                time: '时间：2019年8月1日09:30-11:30',
                place: '地点：7.1馆蓝色活动区'
            },
            boxWarp1: {
                title: '广州禹拓新品发布会暨签约仪式',
                company: '公司：广州禹拓测控技术有限公司',
                time: '时间：2019年8月1日11:00-12:00',
                place: '地点：6.1馆虹色活动区'
            },
            boxWarp2: {
                title: '德克液压破碎锤新品发布会',
                company: '公司：浙江岭德重工有限公司',
                time: '时间：2019年8月1日15:30-16:30',
                place: '地点：7.1馆金色活动区'
            }
        }
    },
    news: {
        details: {
            title: '资讯详情',
        }
    },
    confer: {
        learnMore: '了解详情',
    },
    'Validate': {
        'CompanyNotNull': '公司不能为空',
    }
};
