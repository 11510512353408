import Vue from 'vue'
import Vuex from 'vuex'
import Audience from './modules/Audience'
Vue.use(Vuex)
const conmart = process.env.NODE_ENV !== 'production'

export function createStore() {
    return new Vuex.Store({
        modules: {
            Audience,
        }
    })
}