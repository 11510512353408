import Vue from 'vue'
import App from './App.vue'
import {createRouter} from './router'
import {createStore} from './store'
import {sync} from 'vuex-router-sync'
import VueI18n from 'vue-i18n'
import VueLazyload from 'vue-lazyload'
import './style/restet.scss'
import AppSwiper from './components/app-swiper'; //全局轮播图
import AppSwiperItem from './components/app-swiper-item';


Vue.component(AppSwiper.name, AppSwiper);
Vue.component(AppSwiperItem.name, AppSwiperItem);

Vue.use(VueI18n); //调用语言包
Vue.use(VueLazyload);
Vue.mixin({
    methods: {
        $transformAPILang() {
            return {'zh': 'chs', 'en': 'eng'}[this.$route.params.lang]
        }
    },
    computed: {
        bannerList() {
            if (this.$route.params.lang === 'zh') {
                return [
                    // require('@/assets/images/bannerList/1.zh.jpg'),
                    // require('@/assets/images/bannerList/2.zh.jpg'),
                    // require('@/assets/images/bannerList/3.zh.jpg'),
                    // require('@/assets/images/bannerList/4.zh.jpg'),
                    // require('@/assets/images/bannerList/2020.1.cn.jpg'),
                    // require('@/assets/images/bannerList/2020.2.1.zh.jpg'),
                    require('@/assets/images/bannerList/2020.2.2.zh.jpg'),
                ];
            }

            return [
                require('@/assets/images/bannerList/2020.2.2.en.jpg'),
                // require('@/assets/images/bannerList/1.en.jpg'),
                // require('@/assets/images/bannerList/2.en.jpg'),
                // require('@/assets/images/bannerList/3.en.jpg'),
                // require('@/assets/images/bannerList/4.en.jpg'),
                // require('@/assets/images/bannerList/2020.1.en.jpg'),
            ];
        },
        pickerMain(){
            return {
                zh:[
                    require('@/assets/images/home/canzhan.zh.jpg'),
                    require('@/assets/images/home/guanzhan.zh.jpg'),
                    require('@/assets/images/home/zhan3.zh.jpg'),

                    require('@/assets/images/exhibitors/1.zh.jpg'),
                    require('@/assets/images/exhibitors/2.zh.jpg'),
                    require('@/assets/images/exhibitors/3.zh.jpg'),
                    require('@/assets/images/exhibitors/footer.zh.jpg'),
                ],
                en:[
                    require('@/assets/images/home/canzhan.en.jpg'),
                    require('@/assets/images/home/guanzhan.en.jpg'),
                    require('@/assets/images/home/zhan3.en.jpg'),

                    require('@/assets/images/exhibitors/1.en.jpg'),
                    require('@/assets/images/exhibitors/2.en.jpg'),
                    require('@/assets/images/exhibitors/3.en.jpg'),
                    require('@/assets/images/exhibitors/footer.en.jpg'),
                ]
            }[this.$route.params.lang];
        }
    }
});

export function createApp(url) {
    const router = createRouter()
    const store = createStore()
    const i18n = new VueI18n({
        locale: router.resolve(url).route.params.lang, // 语言标识
        fallbackLocale: 'zh',
        messages: {
            'zh': require('./i18n/zh'),
            'en': require('./i18n/en')
        }
    })
    sync(store, router)
    const app = new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    })
    return {app, router, store, i18n}
}
